a {
        color: black;
        text-decoration: underline;
        &:link {}
        &:visited {}
        &:hover, &.hover {}
        &:active {}
        &:disabled {

    pointer-events: none;
    cursor: not-allowed;
        }
        &:empty {}
        &:enabled {}
        &:focus {}
        &:focus-within {}
}
author-control {

  & input,
  & textarea,
  & select,
  & datalist,
  & author-select,
  & author-datalist {
    width: 100%;
    max-width: 100%;
    margin: 0;
    vertical-align: top;
  }

  &:hover, &.hover {}

  &:default {}

  &:defined {}

  &.disabled, &[disabled] {
    pointer-events: none;
        }

  &:empty {}

  &:enabled {}

  &:focus {}

  &:focus-within {}

  &:invalid, &[invalid] {}

  &:required, &[required] {}

  &:valid, &[valid] {}
}
author-icon {

  display: inline-flex;
  align-items: center;
  justify-content: center;

  & svg {
    flex: 1 1 auto;
    display: block;
    width: 100%;
    height: 100%;
    pointer-events: none;
  }

  &:hover, &.hover {}
}
button, .button {

  display: inline-flex;
  justify-content: center;
  align-items: center;
  line-height: var(--min-line-height);
  vertical-align: middle;
  user-select: none;
  cursor: pointer;
  font-family: unset;
        @set padding;
        background: lightgrey;
        border-radius: 3px;
        &:visited {}
        &:hover, &.hover {}
        &:active {}
        &:default {}
        &:disabled, &.disabled {

    pointer-events: none;
    cursor: not-allowed;
        }
        &:empty {}
        &:enabled {}
        &:focus {}
        &:focus-within {}
}
input[type="checkbox"] {
        &:hover, &.hover {}
        &:checked {}
        &:default {}
        &:disabled {}
        &:enabled {}
        &:focus {}
        &:indeterminate {}
        &:invalid {}
        &:optional {}
        &:required {}
        &:valid {}
}
input[type="date"], input[type="datetime-local"], input[type="email"], input[type="file"], input[type="month"], input[type="number"], input[type="password"], input[type="range"], input[type="search"], input[type="tel"], input[type="text"], input[type="time"], input[type="url"], input[type="week"] {
  line-height: var(--min-line-height);
  background: transparent;
  border: none;
  &:hover, &.hover {}
  &:active {}
  &:disabled {
    pointer-events: none;
        }
  &:empty {}
  &:enabled {}
  &:focus {}
  &:focus-within {}
  &:invalid {}
  &:in-range {}
  &:optional {}
  &:out-of-range {}
  &:read-only {}
  &:read-write {}
  &:required {}
  &:valid {}
}
meter {
        &:hover, &.hover {}
        &:disabled {}
        &:empty {}
        &:enabled {}
}
progress {
        &:hover, &.hover {}
        &:disabled {}
        &:empty {}
        &:enabled {}
        &:indeterminate {}
}
input[type="radio"] {
        &:hover, &.hover {}
        &:checked {}
        &:default {}
        &:disabled {}
        &:enabled {}
        &:focus {}
        &:indeterminate {}
        &:invalid {}
        &:optional {}
        &:required {}
        &:valid {}
}
select {
  display: inline-flex;
  appearance: none;
  font-family: unset;
  font-size: unset;
  cursor: pointer;
  background: transparent;
  line-height: var(--min-line-height);
        @set padding y left;
        padding-right: 2.236em;
        box-shadow: inset 0 0 0 1px lightgrey;
        border-radius: 3px;
        background: white;
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='currentColor' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' class='feather feather-chevron-down'%3E%3Cpolyline points='6 9 12 15 18 9'%3E%3C/polyline%3E%3C/svg%3E");
        background-repeat: no-repeat;
        background-position: right .618em top 50%;
        background-size: 1em auto;
        &:hover, &.hover {}
        &:disabled {
    pointer-events: none;
        }
        &:focus {}
        &:invalid {}
        &:optional {}
        &:required {}
        &:valid {}
}
table {
        table-layout: fixed;
        width: 100%;
        border-collapse: collapse;
        border-spacing: 0;

        & th,
        & td {
          overflow: hidden;
          text-align: left;
          text-overflow: ellipsis;
          vertical-align: top;
        }

        & caption {
          text-align: left;
        }

        &:empty {}

        &:focus-within {}
}
.tag {

  display: inline-flex;
  justify-content: center;
  align-items: center;
  vertical-align: baseline;
  &:empty {}
  &:focus-within {}
}
textarea {
  overflow: auto;
  vertical-align: top;
  resize: vertical;

  background: transparent;
  border: none;
  &:hover, &.hover {}
  &:active {}
  &:disabled {
    pointer-events: none;
        }
  &:empty {}
  &:enabled {}
  &:focus {}
  &:focus-within {}
  &:invalid {}
  &:optional {}
  &:read-only {}
  &:read-write {}
  &:required {}
  &:valid {}
}
.height.constraint {
  min-height: 400px;
  max-height: initial;
}

.min.height.constraint {
  max-height: initial;
}

.max.height.constraint {
  min-height: initial;
}

.width.constraint {
  width: 100%;
  min-width: 320px;
  max-width: 2560px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 2.36vw;
  padding-right: 2.36vw;
}

.min.width.constraint {
  max-width: initial;
}

.max.width.constraint {
  min-width: initial;
}

@media screen and (max-width: 320px) {
  .width.constraint {
    padding-left: calc(320px * 2.36 / 100);
    padding-right: calc(320px * 2.36 / 100);
  }

  .max.width.constraint {
    padding-left: initial;
    padding-right: initial
  }
}

@media screen and (min-width: 2560px) {
  .width.constraint {
    padding-left: calc(2560px * 2.36 / 100);
    padding-right: calc(2560px * 2.36 / 100);
  }

  .min.width.constraint {
    padding-left: initial;
    padding-right: initial
  }
}
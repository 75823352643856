:root {
    --layout-width-min: 320px;
    --layout-width: 2240px;
    --layout-width-max: 2560px;
    --layout-gutter-x: 2.36vw;
    --layout-gutter-x-min: calc(320px * 2.36 / 100);
    --layout-gutter-x-max: calc(2560px * 2.36 / 100);
    --base-font-size: 16;
    --scale-ratio: 1.618033988749895;
    --min-line-height: 1.381966011250105;
    --root-bg-color: white;
    --text-color: rgb(59,59,59);
    --font-family: system-ui
}
h1 {
        font-size: 2.0581710272714924rem;
        line-height: 1.494306898254324;
        font-weight: bold
}h2 {
        font-size: 1.618033988749895rem;
        line-height: 1.524865826963311;
        font-weight: bold
}h3 {
        font-size: 1rem;
        line-height: 1.6131827700601689;
        font-weight: bold
}h4 {
        font-size: 1rem;
        line-height: 1.6131827700601689;
        font-weight: bold
}h5 {
        font-size: 1rem;
        line-height: 1.6131827700601689;
        font-weight: bold
}h6 {
        font-size: 1rem;
        line-height: 1.6131827700601689;
        font-weight: bold
}legend {
        font-size: 1.272019649514069rem;
        line-height: 1.5637373847492424;
        font-weight: bold
}button, .button {
    padding: 0.6360098247570346em 0.9991018291976704em 0.6360098247570346em 0.9991018291976704em
}select {
    padding-top: 0.6360098247570346em;
    padding-bottom: 0.6360098247570346em;
    padding-left: 0.9991018291976704em
}
/* mobile-landscape */@media screen and (min-width: 480px) {:root {
                line-height: 1.7287911494652006
        }h1 {
                line-height: 1.5504773417564333
        }h2 {
                line-height: 1.5963157348199142
        }h3 {
                line-height: 1.7287911494652006
        }h4 {
                line-height: 1.7287911494652006
        }h5 {
                line-height: 1.7287911494652006
        }h6 {
                line-height: 1.7287911494652006
        }legend {
                line-height: 1.6546230714988113
        }button, .button {
    padding: 0.6360098247570346em 0.9875447597281243em 0.6360098247570346em 0.9875447597281243em
}select {
    padding-top: 0.6360098247570346em;
    padding-bottom: 0.6360098247570346em;
    padding-left: 0.9875447597281243em
}
}
/* tablet-portrait */@media screen and (min-width: 768px) {:root {
                line-height: 1.936886232394258
        }h1 {
                line-height: 1.6515841400602302
        }h2 {
                line-height: 1.7249255689617995
        }h3 {
                line-height: 1.936886232394258
        }h4 {
                line-height: 1.936886232394258
        }h5 {
                line-height: 1.936886232394258
        }h6 {
                line-height: 1.936886232394258
        }legend {
                line-height: 1.8182173076480348
        }button, .button {
    padding: 0.6360098247570345em 0.9337341653869066em 0.6360098247570345em 0.9337341653869066em
}select {
    padding-top: 0.6360098247570345em;
    padding-bottom: 0.6360098247570345em;
    padding-left: 0.9337341653869066em
}
}
/* tablet-landscape */@media screen and (min-width: 992px) {:root {
                line-height: 1.7403519874057038
        }h1 {
                line-height: 1.7302227609631835
        }h2 {
                line-height: 1.8249554399610437
        }h3 {
                line-height: 2.0987379635613026
        }h4 {
                line-height: 2.0987379635613026
        }h5 {
                line-height: 2.0987379635613026
        }h6 {
                line-height: 2.0987379635613026
        }legend {
                line-height: 1.945457269097431
        }button, .button {
    padding: 0.6360098247570345em 0.9856598439930426em 0.6360098247570345em 0.9856598439930426em
}select {
    padding-top: 0.6360098247570345em;
    padding-bottom: 0.6360098247570345em;
    padding-left: 0.9856598439930426em
}
}
/* laptop */@media screen and (min-width: 1200px) {:root {
                font-size: 17px;
                line-height: 1.7899955856208056
        }h1 {
                line-height: 1.8032443375159257
        }h2 {
                line-height: 1.9178403201746277
        }h3 {
                line-height: 2.249028856787844
        }h4 {
                line-height: 2.249028856787844
        }h5 {
                line-height: 2.249028856787844
        }h6 {
                line-height: 2.249028856787844
        }legend {
                line-height: 2.0636086618718705
        }button, .button {
    padding: 0.6360098247570346em 0.9702097799702633em 0.6360098247570346em 0.9702097799702633em
}select {
    padding-top: 0.6360098247570346em;
    padding-bottom: 0.6360098247570346em;
    padding-left: 0.9702097799702633em
}
}
/* monitor */@media screen and (min-width: 1680px) {:root {
                font-size: 21px;
                line-height: 1.8443995288702322
        }h1 {
                line-height: 1.9717556680222539
        }h2 {
                line-height: 2.132190043744437
        }h3 {
                line-height: 2.5958539950029396
        }h4 {
                line-height: 2.5958539950029396
        }h5 {
                line-height: 2.5958539950029396
        }h6 {
                line-height: 2.5958539950029396
        }legend {
                line-height: 2.3362657221205767
        }button, .button {
    padding: 0.6360098247570345em 0.9138564835659921em 0.6360098247570345em 0.9138564835659921em
}select {
    padding-top: 0.6360098247570345em;
    padding-bottom: 0.6360098247570345em;
    padding-left: 0.9138564835659921em
}
}
/* big-monitor */@media screen and (min-width: 1920px) {:root {
                font-size: 25px;
                line-height: 1.8259021881654274
        }h1 {
                line-height: 2.056011333275418
        }h2 {
                line-height: 2.2393649055293414
        }h3 {
                line-height: 2.769266564110487
        }h4 {
                line-height: 2.769266564110487
        }h5 {
                line-height: 2.769266564110487
        }h6 {
                line-height: 2.769266564110487
        }legend {
                line-height: 2.4725942522449293
        }button, .button {
    padding: 0.6360098247570345em 0.8752615645049349em 0.6360098247570345em 0.8752615645049349em
}select {
    padding-top: 0.6360098247570345em;
    padding-bottom: 0.6360098247570345em;
    padding-left: 0.8752615645049349em
}
}
:root {
  background: var(--root-bg-color, initial);
  font-size: 16px;
  line-height: 1.6131827700601689
}

body {
  min-width: 320px;
  font-family: var(--font-family, initial);
  color: var(--text-color, initial);
}